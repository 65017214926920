import "babel-polyfill";

import { strToObj, jsonToStr, str2char, queryString } from './utilities/string.js';
import { browser } from './utilities/browser.js';
import { cookies } from './utilities/cookies.js';
import { dataStorage } from './utilities/dataStorage.js';
import { dateFormat, nowTimeStamp } from './utilities/date.js';
import { getPubtime } from './utilities/pubtime.js';
import { numRound, isInteger, zeroPadding, getNumberByTenThousand } from './utilities/number.js';
import { ajax } from './utilities/ajax.js';
import { platform } from './utilities/platform.js';

require('jquery');

window.util = {
    strToObj: strToObj,
    jsonToStr: jsonToStr,
    str2char: str2char,
    queryString: queryString,
    browser: browser,
    cookies: cookies,
    dataStorage: dataStorage,
    dateFormat: dateFormat,
    nowTimeStamp: nowTimeStamp,
    getPubtime: getPubtime,
    numRound: numRound,
    getNumberByTenThousand: getNumberByTenThousand,
    isInteger: isInteger,
    zeroPadding: zeroPadding,
    ajax: ajax,
    platform: platform,
};