export function ajax(o, limitTime) {
    if (o.lock) {
        if ($(o.lock).data('lock')) {
            return;
        }

        $(o.lock).data('lock', 1);
    }

    return $.ajax({
        type: o.type ? o.type : 'GET',
        url: o.url,
        data: o.data,
        headers: o.headers,
        async: o.async == false ? o.async : true,
        timeout: limitTime || 120000,
        success: function(res) {
            var res = typeof res == 'object' ? res : $.parseJSON(res);

            if (res.code && res.code == 302) {
                var href = window.location.href;

                window.location.href = href.indexOf('org.cn') > -1 ? location.protocol + '//sso.scimall.org.cn' : location.protocol + '//sso.scimall.vip';
                return false;
            }
            o.callback && o.callback(res);

            if (o.lock) {
                $(o.lock).data('lock', 0);
            }
        },
        error: function(errMsg) {
            let tipsTxt = '网络异常，请稍后再试!';

            if (errMsg.statusText == 'timeout') {
                tipsTxt = '请求超时';
            }
            o.errorCallback && o.errorCallback();
            alert(tipsTxt);
            $(o.lock).data('lock', 0);
        }
    });
}
// promise版本
export function ajaxPromise(o, limitTime) {
    return new Promise((resolve, reject) => {
        o = Object.assign(o, {
            callback({ code, data, error = [], msg }) {
                if (typeof code == "undefined" || code) {
                    var href = $('._loginBtn').attr('href');

                    layer.closeAll();
                    layer.msg(error[0] && error[0].value || msg || "未知错误");
                    if (href && code == 301) {
                        setTimeout(function() {
                            window.location.href = href;
                        }, 500)
                    }
                    reject();
                    return false;
                }
                resolve(data);
            },
            errorCallback() {
                layer.closeAll();
                layer.msg("网络异常，请稍后再试!");
                reject();
            }
        })
        ajax(o, limitTime);
    })
}